import { FC, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { UseFormReturn } from 'react-hook-form';

import {
  FileUploadButton,
  InputField,
  RadioField,
  SelectField,
} from '../../common/form';
import DatePickerField from '../../common/form/date-picker-field';
import {
  countrieskeyVlaue,
  getCountryByCode,
} from '../../../constants/countries';
import { getRegionsKeyValue } from '../../../constants/regions';
import CKEditorWrapper from '../../ckeditor';
import { CountryDetail } from '../../../types/common';
import {
  CNY_CURRENCY,
  RUB_CURRENCY,
  USD_CURRENCY,
} from '../../../constants/common';
import { TechniqueDetail } from '../../../types/technique';
import { WorkshopInstructor } from '../../../types/instructor';
import { FileType } from '../../../types/workshop';
import { TUser } from '../../../services/users-service';
import { useAuthState } from '../../../context/auth-state';
import { activeEbanxCountries } from '../../../constants/stripeCountry';

import SelectFilePopup from './selectFilePopup';
import MarketingExpenseComponent from './marketing-expense';
import CurrencyAndPrice from './currency-price';

export interface IWorkshopForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  techniques: TechniqueDetail[];
  instructor: WorkshopInstructor[];
  workshopOwner: TUser | null;
  isAddMode: boolean;
}

export interface ITimeZone {
  label: string;
  value: string;
}

export interface CertificationLevel {
  label: number;
  value: number;
}

const entryFor = [{ label: 'Teacher', value: 'teacher' }];

function validateCurrencyOptions(country?: CountryDetail) {
  const code = country?.currencies[0]?.code;
  let currencyArray = [];

  switch (code) {
    case 'USD':
      currencyArray = [USD_CURRENCY];
      break;
    case 'CNY':
      currencyArray = [CNY_CURRENCY];
      break;
    case 'RUB':
      currencyArray = [RUB_CURRENCY];
      break;
    default:
      if (
        code &&
        country?.currencies[0]?.name &&
        !activeEbanxCountries?.includes(country?.alpha2Code?.toUpperCase())
      ) {
        currencyArray.push(
          {
            value: code,
            label: `${country?.currencies[0]?.name} (${code})`,
          },
          USD_CURRENCY
        );
      } else {
        currencyArray.push(USD_CURRENCY);
      }
  }

  return currencyArray;
}

const CreateWorkshopForm: FC<IWorkshopForm> = ({
  form,
  techniques,
  instructor,
  workshopOwner,
  isAddMode,
}) => {
  const { user } = useAuthState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<FileType>(FileType.banner);

  const [currentCountry, setCurrentCountry] = useState<CountryDetail>();
  const [currentTimezone, setCurrentTimezone] = useState<ITimeZone[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<ITimeZone[]>([]);

  const [certificateLevel, setCertificateLevel] =
    useState<CertificationLevel[]>();

  const watchCountry = form.watch('country');
  const timezone = form.watch('timezone');
  const techniqueSlug = form.watch('techniqueSlug');
  const isTeachingSelf = form.watch('isTeachingSelf');
  const watchWorkshopMode = form.watch('workshopMode');
  const isVirtual = watchWorkshopMode === 'virtual';
  const isCertificationMultiLevel = form.watch('isCertificationMultiLevel');
  const instructorPic = form.watch('instructorPic');

  //Extracting youtube video id--
  const getYouTubeVideoId = (url?: string) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url?.match(regex);
    return match ? match[1] : null;
  };
  const promoVideoId = getYouTubeVideoId(form.watch('promoVideoUrl')); // Extract the video ID

  const embedPromoVideoUrl = `https://www.youtube.com/embed/${promoVideoId}`;
  //--------------

  const selectFile = (buttonName: FileType) => {
    setType(buttonName);
    setOpen(true);
  };

  const techniqueSlugChange = async (v: string) => {
    form.setValue('techniqueSlug', v);
    form.setValue('level', undefined);
  };

  const onCountryChange = async (v: string) => {
    const country = getCountryByCode(v);
    const currTimezone = momentTz.tz.zonesForCountry(v);
    const currencyArray = validateCurrencyOptions(country);

    const timezoneArr: ITimeZone[] = [];
    currTimezone.map((timezone) =>
      timezoneArr.push({ label: timezone, value: timezone })
    );

    setCurrentTimezone(timezoneArr);
    setCurrentCountry(country);
    setCurrencyOptions(currencyArray);
  };

  useEffect(() => {
    if (watchCountry) {
      onCountryChange(watchCountry);
    }
  }, [watchCountry]);

  useEffect(() => {
    if (techniqueSlug) {
      const pbt = techniques?.find((data) => data.slug === techniqueSlug);
      const pbtObj = pbt;
      if (pbtObj?.isCertificationMultiLevel) {
        form.setValue('isCertificationMultiLevel', true);
        const level: CertificationLevel[] = [];
        if (pbtObj.certificationHighestLevel) {
          for (let i = 1; i <= pbtObj.certificationHighestLevel; i++) {
            level.push({ label: i, value: i });
          }
        } else {
          level.push({ label: 1, value: 1 });
        }
        setCertificateLevel(level);
      } else {
        form.setValue('isCertificationMultiLevel', false);
        setCertificateLevel([]);
      }
    }
  }, [techniqueSlug, techniques]);

  const addonAfter = isCertificationMultiLevel
    ? `L ${form.watch('level') ?? ''} ${
        techniques?.filter(
          (data) => data.slug === form.getValues('techniqueSlug')
        )[0]?.name.en ?? ''
      } Workshop`
    : `${
        techniques?.filter(
          (data) => data.slug === form.getValues('techniqueSlug')
        )[0]?.name.en ?? ''
      }  Workshop`;

  return (
    <>
      <Modal
        title="Select"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        destroyOnClose
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Close
          </Button>,
        ]}
        width={1000}
      >
        <SelectFilePopup type={type} form={form} />
      </Modal>
      <Form layout="vertical">
        <Row className="launch_workshop">
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <SelectField
              label="Entry For"
              required={true}
              name="workshopfor"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: entryFor,
                value: 'teacher',
                placeholder: 'Select category',
                onChange: (v) => form.setValue('workshopfor', v),
                disabled: true,
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <SelectField
              label="Technique"
              required
              name="techniqueSlug"
              form={form}
              labelKey="name.en"
              valueKey="slug"
              selectFieldProps={{
                options: techniques,
                loading: techniques?.length < 1,
                placeholder:
                  techniques?.length < 1 ? 'Loading...' : 'Select Technique',
                onChange: techniqueSlugChange,
                showSearch: true,
                allowClear: true,
              }}
            />
          </Col>
          {isCertificationMultiLevel && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <SelectField
                label="Workshop Level"
                required
                name="level"
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  options: certificateLevel,
                  placeholder: 'Workshop Level',
                  onChange: (v) => form.setValue('level', v),
                }}
              />
            </Col>
          )}

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <SelectField
              label="Workshop Mode (Virtual / Physical)"
              required
              name="workshopMode"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: [
                  { label: 'Virtual (online)', value: 'virtual' },
                  { label: 'Physical', value: 'physical' },
                ],
                placeholder: 'Select Workshop Mode',
                onChange: (v) => form.setValue('workshopMode', v),
                showSearch: true,
                allowClear: true,
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <SelectField
              label="Country"
              name="country"
              required
              form={form}
              valueKey="value"
              labelKey="label"
              selectFieldProps={{
                options: countrieskeyVlaue(),
                showSearch: true,
                onChange: (v) => {
                  form.setValue('state', '');
                  form.setValue('city', '');
                  form.setValue('timezone', '');
                  form.setValue('currency', '');
                  form.setValue('ticketPrice', '');
                  form.setValue('country', v);
                },
                placeholder: 'Select country',
              }}
            />
          </Col>

          {!isVirtual && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <SelectField
                label="State"
                required
                name="state"
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  options: watchCountry ? getRegionsKeyValue(watchCountry) : [],
                  placeholder: 'Select State',
                  onChange: (v) => form.setValue('state', v),
                  showSearch: true,
                }}
              />
            </Col>
          )}

          {!isVirtual && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <InputField
                label="City"
                name="city"
                // required
                inputProps={{
                  placeholder: 'city',
                }}
                form={form}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <InputField
              label="Workshop Title"
              name="title"
              required
              inputProps={{
                placeholder: 'Workshop Title',
                addonAfter: addonAfter,
              }}
              form={form}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <SelectField
              label="Time zone"
              required
              name="timezone"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: currentTimezone,
                placeholder: 'Select Timezone',
                showSearch: true,
                onChange: (v) => {
                  form.setValue('timezone', v);
                },
              }}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <SelectField
              label="Language"
              required
              name="language"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                options: [
                  { label: 'English', value: 'en' },
                  { label: 'Chinese', value: 'zh' },
                  { label: 'Spanish', value: 'es' },
                  { label: 'Portuguese', value: 'pt' },
                  { label: 'Italian', value: 'it' },
                  { label: 'French', value: 'fr' },
                  { label: 'Cantonese', value: 'zh-HK' },
                ],
                placeholder: 'Select Language',
                onChange: (v) => form.setValue('language', v),
                showSearch: true,
                allowClear: true,
              }}
            />
          </Col>
          {!isVirtual && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <InputField
                label="Venue Name"
                name="venueName"
                required
                inputProps={{
                  placeholder: 'Venue Name',
                }}
                form={form}
              />
            </Col>
          )}
          {!isVirtual && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <InputField
                label="Address 1"
                name="address1"
                required
                inputProps={{
                  placeholder: 'Address 1',
                }}
                form={form}
              />
            </Col>
          )}
          {!isVirtual && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <InputField
                label="Address 2"
                name="address2"
                inputProps={{
                  placeholder: 'Address 2',
                }}
                form={form}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <InputField
              label="Number of tickets"
              name="totalTickets"
              required
              inputProps={{
                placeholder: 'Number of tickets',
                type: 'number',
              }}
              form={form}
            />
          </Col>
        </Row>
        <Row className="launch_workshop">
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <DatePickerField
              label="Workshop starts at"
              required
              name="startDate"
              form={form}
              format="YYYY-MM-DD HH:mm"
              timezone={timezone}
              datePickerProps={{
                disabledDate: (current) => {
                  const customDate = moment().format('YYYY-MM-DD HH:mm');
                  return (
                    current && current < moment(customDate, 'YYYY-MM-DD HH:mm')
                  );
                },
                disabledMinutes: (selectedHour) => {
                  const disabledMinutes = [];
                  for (let i = 0; i < 60; i++) {
                    if (i % 15 !== 0) {
                      disabledMinutes.push(i);
                    }
                  }
                  return disabledMinutes;
                },

                showTime: {
                  hideDisabledOptions: true,
                  defaultValue: moment('00:00', 'HH:mm'),
                },
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <DatePickerField
              label="Workshop ends at"
              required
              name="endDate"
              form={form}
              format="YYYY-MM-DD HH:mm"
              timezone={timezone}
              datePickerProps={{
                disabledDate: (current) => {
                  const customDate = form.watch('startDate');
                  return (
                    current && current < moment(customDate, 'YYYY-MM-DD HH:mm')
                  );
                },
                disabledMinutes: (selectedHour) => {
                  const disabledMinutes = [];
                  for (let i = 0; i < 60; i++) {
                    if (i % 15 !== 0) {
                      disabledMinutes.push(i);
                    }
                  }
                  return disabledMinutes;
                },
                showTime: {
                  hideDisabledOptions: true,
                },
                onOpenChange: (open) => {
                  const startDate = form.watch('startDate');

                  if (open && startDate) {
                    const endDate = form.watch('endDate');
                    if (!endDate) {
                      const oneHourAfterStartDate = moment(startDate).add(
                        1,
                        'hour'
                      );
                      form.setValue('endDate', oneHourAfterStartDate);
                    }
                  }
                },
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <DatePickerField
              label="Ticket sale closes at"
              required
              name="ticketEndDate"
              form={form}
              format="YYYY-MM-DD HH:mm"
              timezone={timezone}
              datePickerProps={{
                disabledDate: (current) => {
                  const startDate = form.watch('startDate');
                  const oneHourEarlier = moment(startDate)
                    .clone()
                    .subtract(0.99, 'hour');
                  return current && current > oneHourEarlier;
                },
                disabledMinutes: () => {
                  const disabledMinutes = [];
                  for (let i = 0; i < 60; i++) {
                    if (i % 15 !== 0) {
                      disabledMinutes.push(i);
                    }
                  }
                  return disabledMinutes;
                },
                showTime: {
                  hideDisabledOptions: true,
                },
                onOpenChange: (open) => {
                  const startDate = form.watch('startDate');

                  if (open && startDate) {
                    const ticketEndDate = form.watch('ticketEndDate');
                    if (!ticketEndDate) {
                      const oneHourBeforeStartDate = moment(startDate).subtract(
                        1,
                        'hour'
                      );
                      form.setValue('ticketEndDate', oneHourBeforeStartDate);
                    }
                  }
                },
              }}
            />
          </Col>
        </Row>
        <Row className="launch_workshop">
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <RadioField
              name="isTeachingSelf"
              required
              label="Who is Teaching ?"
              form={form}
              renderValue={(plan) => {
                return plan.label;
              }}
              radioFieldProps={{
                options: [
                  {
                    label: 'MySelf',
                    value: true,
                  },
                  {
                    label: 'Other Instructor',
                    value: false,
                  },
                ],
                onChange: (v) => {
                  form.setValue('isTeachingSelf', v?.target?.value);
                  form.setValue('instructor', undefined);
                },
              }}
            />
          </Col>
          {isTeachingSelf === false && (
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <SelectField
                label="Select Instructor"
                name="instructor"
                required
                form={form}
                labelKey="label"
                valueKey="value"
                selectFieldProps={{
                  options: instructor
                    ?.filter((i: WorkshopInstructor) =>
                      user?.assignedInstructor?.includes(i?._id?.toString())
                    )
                    ?.map((i) => ({
                      label: `${i?.fullName} (${i?.email})`,
                      value: i?._id,
                    })),
                  placeholder: 'Workshop Instructor',
                  onChange: (v) => form.setValue('instructor', v),
                  showSearch: true,
                  allowClear: true,
                }}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <InputField
              label="Customer Enquiry Email"
              name="enquiryEmail"
              required
              inputProps={{
                placeholder: 'Enquiry Email',
              }}
              form={form}
            />
          </Col>
        </Row>
        {techniqueSlug && currentCountry && (
          <CurrencyAndPrice
            form={form}
            currencyOptions={currencyOptions}
            currentCountry={currentCountry}
            techniques={techniques}
            workshopOwner={workshopOwner}
          />
        )}
        {currentCountry && (
          <>
            <MarketingExpenseComponent
              form={form}
              currentCountry={currentCountry}
            />
          </>
        )}
        <Row className="launch_workshop">
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item
              name={FileType.banner}
              label="Event Banner Image"
              rules={[{ required: true }]}
            >
              <Button
                type="primary"
                onClick={() => selectFile(FileType.banner)}
                className={
                  form.watch(FileType.banner + 'Url')
                    ? 'buttonSelected'
                    : 'uploadbutton'
                }
                block
              >
                {form.watch(FileType.banner + 'Url') && (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )}{' '}
                Choose a banner image
              </Button>
              <span style={{ color: 'red' }}>
                {' '}
                {form.watch(FileType.banner + 'Url')
                  ? ''
                  : 'Please select a Event Banner Image'}
              </span>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item
              name={FileType.note}
              label="English Language Notes PDF"
              rules={[{ required: true }]}
            >
              <Button
                onClick={() => selectFile(FileType.note)}
                className={
                  form.watch(FileType.note + 'Url')
                    ? 'buttonSelected'
                    : 'uploadbutton'
                }
                type="primary"
                block
              >
                {form.watch(FileType.note + 'Url') && (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )}{' '}
                Choose an English language notes PDF
              </Button>
              <span style={{ color: 'red' }}>
                {' '}
                {form.watch(FileType.note + 'Url')
                  ? ''
                  : 'Please select a English Language Notes PDF'}
              </span>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item
              name={FileType.equipment}
              label="English language Equipment PDF"
              rules={[{ required: true }]}
            >
              <Button
                onClick={() => selectFile(FileType.equipment)}
                className={
                  form.watch(FileType.equipment + 'Url')
                    ? 'buttonSelected'
                    : 'uploadbutton'
                }
                type="primary"
                block
              >
                {form.watch(FileType.equipment + 'Url') && (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )}{' '}
                Choose an English language Equipment PDF
              </Button>
              <span style={{ color: 'red' }}>
                {' '}
                {form.watch(FileType.equipment + 'Url')
                  ? ''
                  : 'Please select a English Language Equipment PDF'}
              </span>
            </Form.Item>
          </Col>
        </Row>
        <Row className="launch_workshop">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name={FileType.ne_note}
              label="Alternate language notes PDF (optional)"
            >
              <Button
                onClick={() => selectFile(FileType.ne_note)}
                className={
                  form.getValues(FileType.ne_note + 'Url')
                    ? 'buttonSelected'
                    : 'uploadbutton'
                }
                type="primary"
                block
              >
                {form.watch(FileType.ne_note + 'Url') && (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )}{' '}
                Choose an alternate language notes PDF
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name={FileType.ne_equipment + 'Url'}
              label="Alternate language equipment PDF (optional)"
            >
              <Button
                onClick={() => selectFile(FileType.ne_equipment)}
                className={
                  form.getValues(FileType.ne_equipment + 'Url')
                    ? 'buttonSelected'
                    : 'uploadbutton'
                }
                type="primary"
                block
              >
                {form.watch(FileType.ne_equipment + 'Url') && (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )}{' '}
                Choose an alternate language Equipment PDF
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row className="launch_workshop">
          {isVirtual && (
            <Col span={24}>
              <CKEditorWrapper
                name="zoomDetails"
                label={
                  <div>
                    <h3 className="no-margin">
                      How to join virtual workshop session?
                    </h3>
                    <p className="no-margin"></p>
                  </div>
                }
                required
                form={form}
              />
            </Col>
          )}

          <Col span={24}>
            <CKEditorWrapper
              name="workshopTimetable"
              label="Workshop timetable"
              required
              form={form}
            />
          </Col>
          <Col span={24}>
            <CKEditorWrapper
              name="additionalNotes"
              label="Additional information"
              form={form}
            />
          </Col>
        </Row>
        <Row className="launch_workshop">
          {techniqueSlug && techniqueSlug === 'pbt' && (
            <>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <FileUploadButton
                  key={instructorPic}
                  form={form}
                  label="Instructor Picture"
                  name="instructorPic"
                  buttonLabel="Click to upload"
                  listType="picture-card"
                  className="workshop-instructor-file-upload"
                  buttonLabelClassname="w-200px"
                  defaultFileList={
                    instructorPic
                      ? [
                          {
                            uid: '-1',
                            name: 'Instructor Pic.png',
                            status: 'done',
                            url: instructorPic.includes('https://')
                              ? instructorPic
                              : `https://pbt-website-images.s3.amazonaws.com/${instructorPic}`,
                          },
                        ]
                      : []
                  }
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <InputField
                  label="Promo Video Url"
                  name="promoVideoUrl"
                  required={techniqueSlug === 'pbt'}
                  inputProps={{
                    placeholder: 'Promo video url',
                  }}
                  form={form}
                />
                {promoVideoId && (
                  <iframe
                    title="promoVideo"
                    src={embedPromoVideoUrl}
                    style={{
                      width: '100%',
                      marginTop: '0.35rem',
                      border: 'none',
                    }}
                  />
                )}
              </Col>
              <Col span={24}>
                <CKEditorWrapper
                  name="descriptionH1"
                  label="Description H1"
                  required={techniqueSlug === 'pbt'}
                  form={form}
                />
              </Col>
              <Col span={24}>
                <CKEditorWrapper
                  name="descriptionH2"
                  label="Description H2"
                  required={techniqueSlug === 'pbt'}
                  form={form}
                />
              </Col>
            </>
          )}
          <Col span={24}>
            <CKEditorWrapper
              name="description"
              label="Description"
              required
              form={form}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateWorkshopForm;
