import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusCircleOutlined, EditFilled } from '@ant-design/icons';
import { Button, Col, message, Row, Table } from 'antd';

import { IExpense, IExpenseFieldValue } from '../../../../types/workshop';
import { addExpense, editExpense } from '../../../../services/workshop-service';
import {
  convertStripeAmountWithoutDecimal,
  handleError,
} from '../../../../utils/common';
import { InputField, SelectField } from '../../../common/form';
import { WorkshopWithIncomeData } from '..';
import { activeEbanxCountries } from '../../../../constants/stripeCountry';

import validationSchema from './validation-schema';
import columns from './columns';

const ExpenseSection = ({
  workshop,
  reloadWorkshop,
}: {
  workshop: WorkshopWithIncomeData | null;
  reloadWorkshop: () => void;
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editItem, setEditItem] = useState<IExpense>();
  const [requesting, setRequesting] = useState(false);

  const totalExpense = useMemo(() => {
    if (!workshop) return 0;

    const totalAmount = workshop?.expenses?.reduce((acc, cur) => {
      if (cur?.amount) acc += cur.amount;
      return acc;
    }, 0);

    return convertStripeAmountWithoutDecimal(totalAmount, workshop?.currency);
  }, [workshop]);

  const form = useForm<IExpenseFieldValue>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handleSubmit = async (formData: IExpenseFieldValue) => {
    setRequesting(true);
    try {
      if (workshop) {
        if (editItem && editItem._id) {
          await editExpense({
            payload: formData,
            workshopId: workshop._id,
            expenseId: editItem._id,
          });
        } else {
          await addExpense({
            payload: formData,
            workshopId: workshop._id,
          });
        }
        message.success(editItem ? 'Expense updated.' : 'Expense added.');
        reloadWorkshop();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setRequesting(false);
      handleShowForm();
    }
  };

  const handleShowForm = () => {
    setShowForm((prev) => !prev);
    setEditItem(undefined);
    form.reset({});
  };

  const tableColumns = [
    ...columns,
    {
      title: 'Actions',
      render: (value: string, record: IExpense) => {
        return (
          <Button type="link" disabled={workshop?.isExpensesFinalized}>
            <EditFilled
              onClick={() => {
                setEditItem(record);
                setShowForm(true);
              }}
            />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (editItem) {
      form.reset({
        ...editItem,
        amount: +convertStripeAmountWithoutDecimal(
          Number(editItem.amount),
          editItem.currency
        ),
      });
    }
  }, [editItem, form]);

  return (
    <div className="expense-table-section">
      <div className="expense-heading">
        <p className="no-margin">Expenses</p>
        <div>
          {!showForm && (
            <Button
              className="action-buttons"
              type="primary"
              onClick={handleShowForm}
              icon={<PlusCircleOutlined />}
              disabled={workshop?.isExpensesFinalized}
            >
              Add Expense
            </Button>
          )}
        </div>
      </div>
      {showForm && (
        <Row>
          <Col span={6}>
            <SelectField
              name="currency"
              form={form}
              labelKey="label"
              valueKey="value"
              selectFieldProps={{
                placeholder: 'Select Currency',
                options: [
                  ...(workshop?.hasPbtAllShare
                    ? activeEbanxCountries?.includes(
                        workshop?.country?.toUpperCase()
                      )
                      ? [
                          {
                            label: 'USD',
                            value: 'usd',
                          },
                        ]
                      : [
                          {
                            label: 'AUD',
                            value: 'aud',
                          },
                        ]
                    : [
                        {
                          label: workshop?.currency?.toUpperCase(),
                          value: workshop?.currency,
                        },
                      ]),
                ],
              }}
            />
          </Col>
          <Col span={6}>
            <InputField
              name="name"
              form={form}
              inputProps={{ placeholder: 'Name (e.g. Facebook Ad)' }}
            />
          </Col>
          <Col span={6}>
            <InputField
              name="amount"
              form={form}
              inputProps={{ placeholder: 'Amount (e.g. 250)' }}
            />
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              onClick={form.handleSubmit(handleSubmit)}
              loading={requesting}
              style={{ width: '98%' }}
            >
              {editItem ? 'Update' : 'Add'}
            </Button>
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              onClick={handleShowForm}
              danger
              style={{ width: '98%' }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      )}
      <Table
        dataSource={workshop?.expenses || []}
        columns={tableColumns}
        pagination={false}
        className="income-table"
      />
      <p style={{ marginTop: '5px', marginBottom: '5px' }}>
        Total Expenses: {totalExpense}
      </p>
    </div>
  );
};

export default ExpenseSection;
