import React, { createElement, FC, useEffect, useRef, useState } from 'react';
import { Drawer, Spin, Table } from 'antd';

import { getWorkshopIncomeData } from '../../../services/workshop-service';
import { Workshop } from '../../../types/workshop';

import ExpenseSection from './expenses';
import ExternalIncomeSection from './external-income';

import './styles.scss';

interface IWorkshopIncomeProps {
  children: FC<{ onClick: () => void }>;
  workshopId: string;
}

export type WorkshopWithIncomeData = Workshop & {
  incomeColumns: {
    key: string;
    value: string;
    title: string;
    tooltip: string;
    dataIndex: string;
  }[];
  incomeData: Record<string, string | number>[];
  hasPbtAllShare: boolean;
};

const WorkshopIncome: FC<IWorkshopIncomeProps> = ({ children, workshopId }) => {
  const refChildren = useRef(children);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workshop, setWorkshop] = useState<WorkshopWithIncomeData | null>(null);

  const fetchWorkshopData = async () => {
    setLoading(true);
    try {
      const { data } = await getWorkshopIncomeData(workshopId);
      setWorkshop(data?.data);
    } catch (err) {
      // handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerVisibility = () => {
    setDrawerVisible((prev) => !prev);
  };

  useEffect(() => {
    if (drawerVisible) fetchWorkshopData();
  }, [drawerVisible]);

  return (
    <>
      <Drawer
        title={
          <p className="no-margin" style={{ fontSize: '1.2rem' }}>
            {workshop?.title ? `${workshop?.title}'s` : "Workshop's"} Income
            Details
          </p>
        }
        onClose={handleDrawerVisibility}
        open={drawerVisible}
        bodyStyle={{ padding: 0 }}
        closable
        destroyOnClose
        width="100%"
        height="100%"
        footer={false}
        placement="right"
        className="workshopIncome"
      >
        <Spin spinning={loading}>
          <div className="income-container">
            <div className="workshop-income-views-container">
              <p className="workshop-income-views-container-title">
                Total Page Views:
              </p>
              <p className="workshop-income-views-container-value">
                {workshop?.eventPageVisitCount ?? 0}
              </p>
            </div>
            <div className="income-table-container">
              <div className="table-header">
                <h2>Total Income</h2>
              </div>
              {
                <Table
                  dataSource={
                    workshop
                      ? workshop?.incomeData?.map((i) => {
                          return workshop?.incomeColumns?.reduce((acc, cur) => {
                            return {
                              ...acc,
                              [cur?.key]: i?.[cur?.key],
                            };
                          }, {});
                        })
                      : []
                  }
                  columns={workshop?.incomeColumns?.map((i) => {
                    return {
                      key: i?.key,
                      title: i?.title,
                      dataIndex: i?.dataIndex,
                    };
                  })}
                  pagination={false}
                  className="income-table"
                />
              }
            </div>
            <div className="expense-income-container">
              <div className="expense">
                <ExpenseSection
                  workshop={workshop}
                  reloadWorkshop={fetchWorkshopData}
                />
              </div>
              <div className="income">
                <ExternalIncomeSection
                  workshop={workshop}
                  reloadWorkshop={fetchWorkshopData}
                />
              </div>
            </div>
          </div>
        </Spin>
      </Drawer>
      {createElement(refChildren.current, { onClick: handleDrawerVisibility })}
    </>
  );
};

export default WorkshopIncome;
