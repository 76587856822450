import { Layout, Menu } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  TeamOutlined,
  FormOutlined,
  CheckSquareOutlined,
  ReadOutlined,
  VideoCameraOutlined,
  ProfileOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { useAuthState } from '../../../context/auth-state';
import { IDealsStatus } from '../../../types/deals';

const { Sider } = Layout;

export const MENUS = [
  {
    label: 'Users',
    key: 'users',
    icon: <TeamOutlined />,
    submenus: [
      {
        label: 'All Users',
        path: '/all-users',
      },
      {
        label: 'All Admins',
        path: '/all-admins',
      },
    ],
  },
  {
    label: 'Master Forms',
    key: 'masterForms',
    icon: <FormOutlined />,
    submenus: [
      {
        label: 'Exercise Categories',
        parentKey: 'masterForms',
        path: '/categories',
      },
      {
        label: 'Exercise Classes',
        parentKey: 'masterForms',
        path: '/classes',
      },
      // {
      //   label: 'Certification Categories',
      //   parentKey: 'masterForms',
      //   path: '/certification-category',
      // },
      {
        label: 'Equipment',
        parentKey: 'masterForms',
        path: '/equipment',
      },
      {
        label: 'Sections',
        parentKey: 'masterForms',
        path: '/sections',
      },
      {
        label: 'Techniques',
        parentKey: 'masterForms',
        path: '/technique',
      },
      {
        label: 'Instructors',
        parentKey: 'masterForms',
        path: '/instructors',
      },
      // {
      //   label: 'Questions',
      //   parentKey: 'masterForms',
      //   path: '/questions',
      // },
      {
        label: 'Email Attachments',
        parentKey: 'masterForms',
        path: '/email-attachments',
      },
      {
        label: 'Flag List',
        parentKey: 'masterForms',
        path: '/flag-list',
      },
      {
        label: 'Blocked teacher Contact Emails',
        parentKey: 'masterForms',
        path: '/blocked-teacher-contact-emails?page=1&limit=10',
      },
    ],
  },
  {
    label: 'Web content',
    key: 'web-content',
    icon: <ProfileOutlined />,
    submenus: [
      {
        label: 'Blog Categories',
        parentKey: 'masterForms',
        path: '/blog-categories',
      },
      {
        label: 'Blog',
        parentKey: 'masterForms',
        path: '/blog',
      },
      {
        label: 'Faq Categories',
        parentKey: 'web-content',
        path: '/faq-category',
      },
      {
        label: 'Faqs',
        parentKey: 'web-content',
        path: '/faq',
      },
      {
        label: 'User Pages',
        parentKey: 'web-content',
        path: '/user-pages',
      },
      {
        label: 'Marketing Assets',
        parentKey: 'web-content',
        path: '/marketing-assets',
      },
      {
        label: 'Testimonials & Endorsement',
        parentKey: 'web-content',
        path: '/testimonials-and-endorsements',
      },
      {
        label: 'Deals',
        parentKey: 'web-content',
        path: `/deals?status=${IDealsStatus.active}`,
      },
    ],
  },
  {
    label: 'Assessment',
    key: 'assessment',
    icon: <CheckSquareOutlined />,
    submenus: [
      {
        label: 'Assessment Categories',
        parentKey: 'assessment',
        path: '/assessment-categories',
      },
      {
        label: 'Assessment Questions',
        parentKey: 'assesment',
        path: '/assessment-questions',
      },
      {
        label: 'Certification Responses',
        parentKey: 'assesment',
        path: '/assessment-response/certification?page=1&limit=10',
      },
      {
        label: 'Approved Certification Responses',
        parentKey: 'assesment',
        path: '/assessment-response-approved/certification?page=1&limit=10',
      },
      {
        label: 'Badge Responses',
        parentKey: 'assesment',
        path: '/assessment-response/badge?page=1&limit=10',
      },
      {
        label: 'Approved Badge Responses',
        parentKey: 'assesment',
        path: '/assessment-response-approved/badge?page=1&limit=10',
      },
    ],
  },
  {
    label: 'Certification Renewal',
    key: 'certificationRenewal',
    icon: <CheckSquareOutlined />,
    submenus: [
      {
        label: 'Questions',
        parentKey: 'certificationRenewal',
        path: '/certification-renewal-questions',
      },
      {
        label: 'Responses',
        parentKey: 'certificationRenewal',
        path: '/certification-renewal-responses?page=1&limit=10',
      },
      {
        label: 'Approved Responses',
        parentKey: 'certificationRenewal',
        path: '/certification-renewal-responses-approved?page=1&limit=10',
      },
      {
        label: 'Uploaded Certficates',
        parentKey: 'certificationRenewal',
        path: '/uploaded-certificate-response?page=1&limit=10',
      },
      {
        label: 'Approved Uploaded Certficates',
        parentKey: 'certificationRenewal',
        path: '/uploaded-certificate-response-approved?page=1&limit=10',
      },
    ],
  },
  {
    label: 'Video',
    key: 'video',
    icon: <VideoCameraOutlined />,
    submenus: [
      {
        label: 'All Videos',
        parentKey: 'video',
        path: '/videos',
      },
      {
        label: 'All Classes',
        parentKey: 'video',
        path: '/class-videos',
      },
      // {
      //   label: 'Muscles Categories',
      //   parentKey: 'video',
      //   path: '/muscle-categories',
      // },
      // {
      //   label: 'Excercise Muscle',
      //   parentKey: 'video',
      //   path: '/muscle',
      // },
      {
        label: 'Exercise Equipment',
        parentKey: 'video',
        path: '/exercise-equipment',
      },
      {
        label: 'Unanswered Comments',
        parentKey: 'video',
        path: '/unanswered-comments?page=1&limit=10',
      },
    ],
  },
  {
    label: 'Workshop',
    key: 'workshops',
    icon: <ReadOutlined />,
    submenus: [
      {
        label: 'Workshops',
        parentKey: 'workshops',
        path: '/workshops?status=upcoming&region=all&createdBy=all',
      },
      {
        label: 'Create Workshops',
        parentKey: 'workshops',
        path: '/create-workshop',
      },
      {
        label: 'New workshops',
        parentKey: 'workshops',
        path: '/new-workshops',
      },
      {
        label: 'Search attendees',
        parentKey: 'workshops',
        path: '/search-attendees',
      },
      {
        label: 'Credit List',
        parentKey: 'workshops',
        path: '/credit-list',
      },
      {
        label: 'Create Certificate',
        parentKey: 'workshops',
        path: '/create-certificate',
      },
      {
        label: 'Files Directory',
        parentKey: 'workshops',
        path: '/files-directory',
      },
    ],
  },
  {
    label: 'Gift Voucher',
    key: 'gift-voucher',
    icon: <ProfileOutlined />,
    submenus: [
      {
        label: 'Gift Voucher Products',
        parentKey: 'gift-voucher-products',
        path: '/gift-voucher-products',
      },
      {
        label: 'Gift Voucher Orders',
        parentKey: 'gift-voucher-orders',
        path: '/gift-voucher-orders',
      },
    ],
  },
  {
    label: 'Data Download',
    key: 'data-download',
    icon: <DownloadOutlined />,
    submenus: [
      {
        label: 'User Data',
        parentKey: 'user-data-download',
        path: '/user-data-download',
      },
      {
        label: 'Workshop Data',
        parentKey: 'workshop-data-download',
        path: '/workshop-data-download',
      },
    ],
  },
];

const AppSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const location = useLocation();
  const { user } = useAuthState();

  const selectedMenu = useMemo(() => {
    return MENUS.find((m) =>
      m.submenus.find((s) => s.path.split('?')[0] === location.pathname)
    );
  }, [location.pathname]);

  const allowedSections = useMemo(
    () => user?.allowedSections,
    [user?.allowedSections]
  );
  useEffect(() => {
    if (
      location.search &&
      location.pathname !== '/videos' &&
      location.pathname !== '/class-videos'
    )
      setSelectedKeys([location.pathname + location.search]);
    else setSelectedKeys([location.pathname]);

    if (selectedMenu) {
      setOpenKeys([selectedMenu.key]);
    } else {
      setOpenKeys([]);
    }
  }, [location.pathname, location.search, selectedMenu]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setSelectedKeys([path]);
  };

  return (
    <div
      style={{
        height: `calc(100vh - 1px)`,
        position: 'sticky',
        top: 0,
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
    >
      <div>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={handleCollapse}
          width={280}
          style={{ background: 'none' }}
        >
          <Menu
            onOpenChange={(keys) => setOpenKeys(keys)}
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedKeys.map((i) => i?.split('?')[0])}
          >
            {MENUS.map((menu) => {
              const isMenuAllowed = allowedSections?.some((i) => {
                if (!i?.key || !menu?.key) return false;
                return i.key === menu.key || menu.key.includes(i.key);
              });

              return isMenuAllowed ? (
                <Menu.SubMenu
                  key={menu.key}
                  icon={menu.icon}
                  title={menu.label}
                >
                  {menu.submenus.map((sub) => {
                    const isSubsectionAllowed = allowedSections
                      ?.find((i) => {
                        if (!i?.key || !menu?.key) return false;
                        return i.key === menu.key || menu.key.includes(i.key);
                      })
                      ?.subSections?.some(
                        (i) => i?.key.split('?')[0] === sub.path.split('?')[0]
                      );
                    return isSubsectionAllowed ? (
                      <Menu.Item
                        key={sub.path?.split('?')[0]}
                        onClick={() => handleNavigation(sub.path)}
                      >
                        {sub.label}
                      </Menu.Item>
                    ) : null;
                  })}
                </Menu.SubMenu>
              ) : null;
            })}
          </Menu>
        </Sider>
      </div>
    </div>
  );
};

export default AppSidebar;
